body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-color: #f7f7f7;
  min-width: 600px;
}

#login-form {
  text-align: center;
}

.settings-module {
  padding: 20px;
  margin-bottom: 20px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

pre {
  margin: 0;
}
