#cuda-body {
  display: flex;
}

div.component-selector {
  min-width: 200px;
}

div.component-view {
  flex-grow: 1;
  max-width: 1020px;
}
