span.cuda-side-radio-wrapper {
  position: relative;
  display: inline-flex;
  transition: none;
  align-items: center;
  margin: 5px 0px;
}

span.cuda-side-radio-wrapper:first-child span.cuda-side-radio-toggle, 
span.cuda-side-radio-wrapper:last-child span.cuda-side-radio-toggle {
  border-right: 1px solid #DDDDDD;
}

span.cuda-side-radio-wrapper:first-child span.cuda-side-radio-toggle {
  border-left: 1px solid #DDDDDD;
  border-radius: 2px 0 0 2px;
} 

span.cuda-side-radio-wrapper:last-child span.cuda-side-radio-toggle {
  border-radius: 0 2px 2px 0;
}

span.cuda-side-radio-wrapper span.cuda-side-radio-toggle svg {
  margin-right: 5px;
}

span.cuda-side-radio-wrapper span.cuda-side-radio-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 112px;
  line-height: 40px;
  font-size: 14px;
  border: 1px solid #DDDDDD;
  border-left: 0;
  background-color: #FFFFFF;
}

span.cuda-side-radio-wrapper span.cuda-side-radio-toggle.selected {
  border: 1px solid #0088CE;
  background-color: #0088CE;
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5);
  color: #FFFFFF;
}

span.cuda-side-radio-wrapper input[type="radio"] {
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
}

span.cuda-side-radio-wrapper input[type="radio"]:hover {
  cursor: pointer;
}
