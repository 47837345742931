html, body, #root, .App, .App > div {
  height: 100%;
  color: rgba(0,0,0,1);
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.section-title {
  padding: 20px 0 20px 0;
  font-size: 22px;
  font-weight: 300;
  color: rgba(0,0,0,0.7);
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
